import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {BtnParrilla} from '../../BtnParrilla';
import {BtnReplay} from '../../BtnReplay';
import {
  GlobalStylePage,
  ContArrow,
  ContImages,
  ImagesItem,
  ContImagesSquare,
  ContCreditos,
  TextCreditos,
  TextCreditosFooter,
  TitulosCreditos,
  TextosCreditos,
  TextoApoyoCreditos,
  ContImageCreditos,
  ContTextosCreditos,
} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft, ArrowNavLeftCreditos} from '../../ArrowsNavigation';
import {GlobalStyle, ContImgLeft, ContImgRight} from '../../../styles/GlobalStyles';
import {gsap} from 'gsap';
import useSound from 'use-sound';
import imgLeft from '../../../images/obras/obra-15/obra.png';
import imgRight from '../../../images/obras/obra-1/obra.jpeg';
import img01 from '../../../images/obras/obra-1/mini.jpg';
import img02 from '../../../images/obras/obra-2/mini.jpg';
import img03 from '../../../images/obras/obra-3/mini.jpg';
import img04 from '../../../images/obras/obra-4/mini.jpg';
import img05 from '../../../images/obras/obra-5/mini.jpg';
import img06 from '../../../images/obras/obra-6/mini.jpg';
import img07 from '../../../images/obras/obra-7/mini.jpg';
import img08 from '../../../images/obras/obra-8/mini.jpg';
import img09 from '../../../images/obras/obra-9/mini.jpg';
import img10 from '../../../images/obras/obra-10/mini.jpg';
import img11 from '../../../images/obras/obra-11/mini.jpg';
import img12 from '../../../images/obras/obra-12/mini.jpg';
import img13 from '../../../images/obras/obra-13/mini.png';
import img14 from '../../../images/obras/obra-14/mini.jpg';
import img15 from '../../../images/obras/obra-15/mini.jpg';
import imgSquare from '../../../images/obras/obra-6/cubo.png';
import imgFonca from '../../../images/fonca.png';

const arImgs = [
  img01, img02, img03, img04, img05, img06, img07, img08, img09, img10, img11, img12, img13, img14, img15,
];

const arNav = {
  top: null,
  right: 1,
  bottom: null,
  left: 15,
};

const CreditosComp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);
  const [showImageIndex, setShowImageIndex] = useState(0);
  const [playImageIndex, setPlayImageIndex] = useState(true);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchImages();
    setReLaunchAll(!reLaunchAll);
  };
  const fnReLaunchImages = () => {
    setShowImageIndex(0);
    setPlayImageIndex(true);
  };


  const suffleImg = () => {
    if ( playImageIndex ) {
      setTimeout(function () {
        // const index = (showImageIndex === 14) ? setPlayImageIndex(false) : showImageIndex + 1;
        let index;
        if ( showImageIndex === 14 ) {
          index = 0;
          setPlayImageIndex(false);
        } else {
          index = showImageIndex + 1;
        }

        if ( index > 0 ) {
          try {
            setShowImageIndex(index);

            console.log('index ->', index);

            let el = document.getElementsByClassName(`imgs-suffle`);

            for ( let i = 0; i < el.length; i++ ) {
              // el[i].classList.remove("rojo");
              el[i].classList.add('no-show');
            }

            el = document.getElementById(`img-${index}`).classList;
            el.remove('no-show');
          } catch ( e ) {
            console.log(e);
          }
        }

      }, 500);
    }
  };

  const showImages = () => {
    return (
      <ContImages>
        {arImgs.map((item, index) => {
          return (
            <ImagesItem key={index} id={`img-${index}`} className={`imgs-suffle no-show`}>
              <img src={item} alt={''}/>
            </ImagesItem>
          );
        })}
      </ContImages>
    );
  };

  const showSquare = () => {
    return (
      <ContImagesSquare>
        <ContCreditos img={imgSquare}>
          <TextCreditos>CREDITOS</TextCreditos>
          <ContTextosCreditos>
            <TitulosCreditos>"mi vértigo cotidiano"</TitulosCreditos>
            <TextosCreditos>Georgina Quintana.</TextosCreditos>
          </ContTextosCreditos>
          <ContTextosCreditos>
            <TitulosCreditos>Ilustraciones a color:</TitulosCreditos>
            <TextosCreditos>La Biblia en imágenes. Del taller de Lucas Cranach <i>(1534)</i></TextosCreditos>
          </ContTextosCreditos>
          <ContTextosCreditos>
            <TitulosCreditos>Textos:</TitulosCreditos>
            <TextosCreditos>T.S. Elliot. Pequeño vértigo, un yo habitable.</TextosCreditos>
            <TextosCreditos>Octavio Paz. Pasado en Claro.</TextosCreditos>
          </ContTextosCreditos>
          <ContTextosCreditos>
            <TitulosCreditos>Diseño digital:</TitulosCreditos>
            <TextosCreditos>roberto mosqueira.</TextosCreditos>
          </ContTextosCreditos>
          <ContTextosCreditos>
            <TitulosCreditos>Developer:</TitulosCreditos>
            <TextosCreditos>Rafael Guzmán.</TextosCreditos>
          </ContTextosCreditos>
          <ContTextosCreditos>
            <TextoApoyoCreditos className={'margin-top'}>Este proyecto se realizó con el apoyo del</TextoApoyoCreditos>
            <TextoApoyoCreditos>Sistema Nacional de Creadores de Arte</TextoApoyoCreditos>
          </ContTextosCreditos>
          <ContTextosCreditos>
            <ContImageCreditos>
              <img src={imgFonca} alt={''}/>
            </ContImageCreditos>
          </ContTextosCreditos>
          <TextosCreditos>CDMX, México 2021</TextosCreditos>
        </ContCreditos>
      </ContImagesSquare>
    );
  };

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft}/>;
  };
  const fnRight = () => {
    return <ContImgRight src={imgRight}/>;
  };

  useEffect(() => {

  }, [reLaunchAll]);

  useEffect(() => {
    // console.log('arImgs ->', arImgs);
    suffleImg();
  }, [showImageIndex]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && arNav.left === 'creditos' ? <ArrowNavLeftCreditos toPage={arNav.left}/> :
                <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}}
                  className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            {/*<Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>*/}
            {playImageIndex
              ? showImages()
              : showSquare()
            }
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default CreditosComp;
