import styled, {createGlobalStyle} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../../styles/GlobalStyles';

export const GlobalStylePage = createGlobalStyle`
  .no-show{
    display: none;
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`
export const ContImages = styled.div`
  width: 230px;
  height: 230px;
  z-index: 1;
`
export const ImagesItem = styled.div`
  position: absolute;
  width: 230px;
  height: 230px;
  z-index: 1;
`
export const ContImagesSquare = styled.div`
  //position: absolute;
  height: 100%;
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 700px;
    height: 700px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 830px;
    height: 900px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 830px;
    height: 830px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 600px;
    height: 600px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 800px;
    height: 800px;
  }
  @media(min-width: 1920px){
    width: 800px;
    height: 800px;
  }
`
export const ContCreditos = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 15px;
  background-image: url(${props => props.img});
  background-size: cover;
  
  font: 20px ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorMenuPrimary};
`
export const TextCreditos = styled.div`
  font-family: ${GlobalFonts.fontBold};
  text-align: center;
  color: ${GlobalColors.colorMenuPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 32px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 22px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 32px;
  }
  @media(min-width: 1920px){
    font-size: 32px;
  }
`
export const ContTextosCreditos = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const TitulosCreditos = styled.div`
  padding: 10px 0 0;
  font-family: ${GlobalFonts.fontBold};
  text-align: center;
  color: ${GlobalColors.colorMenuPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 30px;
  }
  @media(min-width: 1920px){
    font-size: 30px;
  }
`
export const TextosCreditos = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  text-align: center;
  color: ${GlobalColors.colorMenuPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 14px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
    font-size: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 14px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 18px;
  }
  @media(min-width: 1920px){
    font-size: 18px;
  }
`
export const TextoApoyoCreditos = styled.div`
  font-family: ${GlobalFonts.fontBold};
  text-align: center;
  color: ${GlobalColors.colorMenuPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 28px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 18px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 20px;
  }
  @media(min-width: 1920px){
    font-size: 22px;
  }
`
export const ContImageCreditos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    &>img{
      width: 80%;
    }  
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const TextCreditosFooter = styled.div`
  padding-bottom: 15px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 12px;
  text-align: center;
  color: ${GlobalColors.colorMenuPrimary};
`
